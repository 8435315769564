import React from 'react'
import FAQWrapper from './FAQWrapper'

const FAQ = () => {
  return (
    <FAQWrapper>
      <details>
        <summary>How do I get started?</summary>
        <p>
          You can get started with Swarmia by{' '}
          <a href="/demo/">
            scheduling a meeting with one of our product experts
          </a>
          . They’re happy to give you a tour of the product, answer any
          questions you may have, and work with your team to tailor a trial/POC
          plan for your organization.
        </p>
      </details>
      <details>
        <summary>
          How do I create a software capitalization report with Swarmia?
        </summary>
        <p>Setting up software capitalization in Swarmia is simple:</p>
        <ul>
          <li>
            First, set up rules for what qualifies as work than can be
            capitalized. As a best practice, we recommend using an issue custom
            field for tracking this work.
          </li>
          <li>
            After the setup, Swarmia will process your activity data monthly to
            generate CapEx reports. You can download these reports for the whole
            year or month-by-month.
          </li>
        </ul>
        <p>
          If you’re interested in using Swarmia for software capitalization
          reporting, contact your account manager or email us at
          hello@swarmia.com.
        </p>
      </details>
      <details>
        <summary>How does Swarmia model engineering effort?</summary>
        <p>
          Swarmia models the effort of each software engineer based on their
          GitHub contributions and issue tracker activity. Since engineering
          teams often use Swarmia in their daily work, our data quality is
          generally higher than that of competing solutions.
        </p>
      </details>
      <details>
        <summary>
          Can I lock in calendar months once they’ve been capitalized?
        </summary>
        <p>
          Yes, you retain full control of the capitalization data. We provide
          monthly summaries that don’t change unless you refresh them, and
          you’re in full control of the master data.
        </p>
      </details>
      <details>
        <summary>Can I add salary data from my HR system to Swarmia?</summary>
        <p>
          Instead of having to import data from your HR system directly into
          Swarmia, you can easily blend in salary or cost data exports into the
          spreadsheet reports you download from Swarmia.
        </p>
        <p>
          This way, you remain in full control over who gets access to the
          salary data.
        </p>
      </details>
      <details>
        <summary>Who will have access to Swarmia?</summary>
        <p>
          Anyone in your organization with GitHub credentials can access
          Swarmia. We also support Single Sign-On via Okta.
        </p>
        <p>
          Finance and accounting teams as well as external auditors typically
          interact with Swarmia through the spreadsheet reports for software
          capitalization.
        </p>
      </details>
      <details>
        <summary>
          Can I get a copy of Swarmia’s SOC 2 Type 2 audit report?
        </summary>
        <p>
          Absolutely. Reach out to hello@swarmia.com or your account manager to
          get your copy.
        </p>
        <p>
          We’re happy to share the report with anyone we have a legitimate
          business relationship with. If you’re a security team member looking
          for an answer to this question, please make sure that your point of
          contact for the Swarmia evaluation has talked to your account manager
          about it.
        </p>
      </details>
      <details>
        <summary>Can you fill out our security questionnaire?</summary>
        <p>
          We’d be happy to complete a security questionnaire for any company
          considering one of our paid plans. Please reach out to your account
          manager or hello@swarmia.com to get started.
        </p>
      </details>
      <details>
        <summary>Do you support Single Sign-On (SSO) / SAML?</summary>
        <p>
          Swarmia supports Single Sign-On through Okta and secure login through
          GitHub.
        </p>
        <p>
          We offer Single Sign-On (SSO) through Okta in our Enterprise plan.
          This is especially useful when you’d like to invite non-engineering
          stakeholders to Swarmia.
        </p>
        <p>
          Customers in our Free, Lite, and Standard plans can log in to Swarmia
          with their GitHub login. This allows you to use an identity provider
          that your organization already trusts.
        </p>
        <p>
          The typical Single Sign-On features work through the GitHub
          authentication:
        </p>
        <ul>
          <li>
            Anyone with access to the relevant GitHub organization will also be
            able to access Swarmia, so you don’t need to create Swarmia user
            accounts for each employee.
          </li>
          <li>
            When an account is disabled from your SSO provider, if you’ve
            configured it to be deleted from GitHub the change will also
            propagate to Swarmia automatically.
          </li>
        </ul>
      </details>
    </FAQWrapper>
  )
}

export default FAQ
